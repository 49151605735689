import React from "react"
import { Helmet } from "react-helmet"
import { Header, LandingHeader } from '../components/header'
import BwwFooter from "./bww-footer"
import CookieBanner from "./cookie-banner"

import 'bootstrap/dist/css/bootstrap-grid.min.css'
import '../styles/main.scss'

const Layout=({ children, location={}, headerType="full" }) => {

    return(
        <>
          <Helmet>
            <link rel="canonical" href={`https://go-write.co.uk${location.pathname}`}></link>
            <meta name="facebook-domain-verification" content="3mstr0co6lmkgmu1hr6fmz3oclp8hi" />
          </Helmet>
            { headerType === "full" && (
              <Header />
            )}
            { headerType === "landing" && (
              <LandingHeader />
            )}
            <main className="main-content">
                { children }
            </main>
            <CookieBanner />
            <BwwFooter />
        </>
    )
}

export default Layout 